import React, { useState, useEffect } from "react";
import styled from "styled-components";
import Div100vh from "react-div-100vh";
import Modal from "react-modal";
import { Carousel } from "react-responsive-carousel";
import { RemoveScroll } from "react-remove-scroll";

// Styles
import "../styles/gallery.css";

// Components
import { AspectRatioImageContainer } from "../containers/aspect-ratio-image-container";
import { GalleryIndicators } from "./gallery-indicators";

const GalleryContainer = styled.div`
  position: relative;

  width: 100%;
  height: 100%;
  max-height: 100%;
  padding: 25px 25px 30px 25px;

  @media (max-width: 767px) {
    padding: 0 0 15px 0;
  }

  & .carousel.carousel-slider,
  & .slider-wrapper,
  & .product-gallery,
  & .slider,
  & .slide,
  & video,
  & img {
    height: 100%;
  }

  & .lightbox-gallery {
    & .control-arrow {
      width: 50%;
      bottom: 50px;
    }

    & .control-dots {
      bottom: -5px;

      z-index: 100;

      @media (max-width: 767px) {
        text-align: left;

        & .dot {
          font-size: 11px;
          line-height: 20px;
        }
      }
    }
  }

  & .carousel.carousel-slider {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    & .carousel .control-dots {
      bottom: 0;
    }

    & .slider-wrapper {
      order: 1;
    }
    & .control-dots {
      order: 12;
    }
  }

  & .dot {
    & span {
      opacity: 0;
    }

    &:hover {
      & span {
        opacity: 1;
      }
    }
  }

  & .dot.selected {
    & span {
      opacity: 1;
    }
  }
`;

const ImageContainer = styled.div`
  position: relative;

  width: 100%;
  height: 100%;

  overflow: hidden;

  & > div {
    max-height: calc(100vh - 50px);
  }

  & img {
    width: 100%;
    height: 100%;
    object-fit: contain;

    margin: 0 auto;
    display: block;

    @media (max-width: 767px) {
      object-position: top;
    }

    @media (max-width: 500px) {
      object-fit: cover;
    }
  }
`;

const LightboxContainer = styled.div`
  background-color: #fafaf5;
  z-index: 100;

  & > div {
    background-color: #fafaf5;

    overflow: hidden;

    & .lightbox-wrapper {
      position: relative;
      width: 100%;
      height: 100%;
    }
  }
`;

const CloseLightbox = styled.button`
  position: absolute;
  top: 11px;
  right: 14px;

  z-index: 200;

  line-height: 1;
  font-size: 19px;

  padding: 10px;

  @media (max-width: 767px) {
    display: none;
  }
`;

const CloseLightboxMobile = styled.button`
  position: absolute;
  bottom: 11px;
  right: 15px;

  display: none;

  font-size: 11px;
  line-height: 20px;

  @media (max-width: 767px) {
    display: block;
    z-index: 200;
  }
`;

Modal.setAppElement("#___gatsby");

export const LightboxGallery = ({
  images,
  title,
  isLightboxOpen,
  setLightBoxOpen,
}) => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const [allGalleryContent, setAllGalleryContent] = useState([]);
  const [totalSlides, setTotalSlides] = useState(null);

  useEffect(() => {
    setTotalSlides(images.length);
    setAllGalleryContent(images);
  }, []);

  const updateCurrentSlide = (index) => {
    if (currentSlide !== index) {
      setCurrentSlide(index);
    }
  };

  const galleryContent = images.map((image, index) => {
    return (
      <ImageContainer key={`homepage_images_${index}`}>
        <AspectRatioImageContainer image={null} padding={134}>
          <img
            src={image.originalSrc}
            // alt={content.image.alt}
            loading={
              index <= 2 || index === images.length - 1 ? `eager` : `lazy`
            }
          />
        </AspectRatioImageContainer>
      </ImageContainer>
    );
  });

  useEffect(() => {
    if (typeof window !== undefined) {
      document.addEventListener("keydown", (event) => onEscape(event));
    }
  }, [isLightboxOpen]);

  const onEscape = (event) => {
    if (event.keyCode === 27 && isLightboxOpen === true) {
      document.body.style.overflow = "";
      setLightBoxOpen(false);
    }
  };

  return (
    <RemoveScroll>
      <Modal
        isOpen={isLightboxOpen}
        className="product-lighbox-modal"
        contentLabel={`${title} Gallery`}
      >
        <LightboxContainer>
          <Div100vh>
            <div className="lightbox-wrapper">
              <CloseLightbox onClick={() => setLightBoxOpen(false)}>
                <svg
                  width="15"
                  height="15"
                  viewBox="0 0 15 15"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M14 1L1.00072 13.9993"
                    stroke="black"
                    strokeWidth="0.75"
                  />
                  <path
                    d="M14 14L1.00072 1.00072"
                    stroke="black"
                    strokeWidth="0.75"
                  />
                </svg>
              </CloseLightbox>

              <GalleryContainer>
                <Carousel
                  showThumbs={false}
                  autoPlay={false}
                  showIndicators={true}
                  showStatus={false}
                  infiniteLoop={true}
                  useKeyboardArrows={true}
                  className="product-gallery lightbox-gallery"
                  selectedItem={currentSlide}
                  onChange={(index) => updateCurrentSlide(index)}
                  stopOnHover={false}
                  interval={7000}
                  transitionTime={750}
                  renderIndicator={(
                    onClickHandler,
                    isSelected,
                    index,
                    label
                  ) => (
                    <GalleryIndicators
                      onClickHandler={onClickHandler}
                      isSelected={isSelected}
                      index={index}
                      label={label}
                    />
                  )}
                >
                  {galleryContent}
                </Carousel>
              </GalleryContainer>

              <CloseLightboxMobile onClick={() => setLightBoxOpen(false)}>
                (close)
              </CloseLightboxMobile>
            </div>
          </Div100vh>
        </LightboxContainer>
      </Modal>
    </RemoveScroll>
  );
};
