import React, { useContext } from "react";

// Context
import { Popup } from "../context/popup";
import { AddedToBag } from "../context/added-to-bag";

export const Buttons = ({
  context,
  available,
  productVariant,
  quantity,
  product,
}) => {
  // Popups
  const [activePopup, setActivePopup] = useContext(Popup);
  const [activeBagItem, setActiveBagItem] = useContext(AddedToBag);

  const handleAddToCart = () => {
    context.addVariantToCart(productVariant.shopifyId, quantity);
    setActivePopup(`added-to-bag`);
    setActiveBagItem(product);
  };

  return (
    <button
      disabled={!available}
      onClick={handleAddToCart}
      className="add-to-cart"
    >
      {available ? `(add to bag)` : `(sold out)`}
    </button>
  );
};
