import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Carousel } from "react-responsive-carousel";
import "../styles/gallery.css";

// Components
import { AspectRatioImageContainer } from "../containers/aspect-ratio-image-container";
import { GalleryIndicators } from "./gallery-indicators";
import { LightboxGallery } from "../images/lightbox-gallery";

const GalleryContainer = styled.div`
  position: relative;
  max-height: 100%;

  cursor: pointer;

  & .carousel.carousel-slider,
  & .slider-wrapper,
  & .product-gallery,
  & .slider,
  & .slide,
  & video,
  & img {
    height: 100%;
  }

  & .product-gallery {
    & .carousel .slider-wrapper.axis-horizontal .slider {
      align-items: flex-end;
    }

    & .control-dots {
      bottom: -49px;
    }
  }

  & .carousel.carousel-slider {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    & .slider-wrapper {
      order: 1;
    }
    & .control-dots {
      order: 12;
    }
  }

  & .dot {
    & span {
      opacity: 0;
    }

    &:hover {
      & span {
        opacity: 1;
      }
    }
  }

  & .dot.selected {
    & span {
      opacity: 1;
    }
  }

  & .carousel.carousel-slider .control-arrow {
    display: none;
  }

  @media (max-width: 767px) {
    & .control-dots {
      display: none;
    }
  }
`;

const ImageContainer = styled.div`
  position: relative;

  width: 100%;
  height: 100%;

  overflow: hidden;

  & img {
    width: 100%;
    height: 100%;
    max-height: 100%;

    object-fit: cover;
  }

  @media (max-width: 767px) {
    max-width: 66.666%;
    margin: 0 auto;
  }
`;

export const ProductGallery = ({
  images,
  title,
  maxHeight,
  isLightboxOpen,
  setLightBoxOpen,
}) => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const [allGalleryContent, setAllGalleryContent] = useState([]);
  const [totalSlides, setTotalSlides] = useState(null);

  useEffect(() => {
    setTotalSlides(images.length);
    setAllGalleryContent(images);
  }, []);

  const updateCurrentSlide = (index) => {
    if (currentSlide !== index) {
      setCurrentSlide(index);
    }
  };

  const galleryContent = images.map((image, index) => (
    <ImageContainer
      key={`object_image_${index}_${image.id}`}
      onClick={() => setLightBoxOpen(true)}
      maxHeight={maxHeight}
    >
      <AspectRatioImageContainer
        image={null}
        padding={image.width > image.height ? 75 : 130}
      >
        <img
          src={image.originalSrc !== undefined ? image.originalSrc : image.src}
          // alt={content.image.alt}
          loading={index <= 2 || index === images.length - 1 ? `eager` : `lazy`}
        />
      </AspectRatioImageContainer>
    </ImageContainer>
  ));

  return (
    <>
      <GalleryContainer maxHeight={maxHeight}>
        <Carousel
          showThumbs={false}
          autoPlay={false}
          showIndicators={true}
          showStatus={false}
          infiniteLoop={true}
          useKeyboardArrows={false}
          className="product-gallery"
          selectedItem={currentSlide}
          onChange={(index) => updateCurrentSlide(index)}
          stopOnHover={false}
          interval={7000}
          transitionTime={750}
          renderIndicator={(onClickHandler, isSelected, index, label) => (
            <GalleryIndicators
              onClickHandler={onClickHandler}
              isSelected={isSelected}
              index={index}
              label={label}
            />
          )}
        >
          {galleryContent}
        </Carousel>
      </GalleryContainer>

      {isLightboxOpen && (
        <LightboxGallery
          images={images}
          isLightboxOpen={isLightboxOpen}
          setLightBoxOpen={setLightBoxOpen}
          title={title}
        />
      )}
    </>
  );
};
