import React, { useContext, useState, useEffect } from "react";
import { Link } from "gatsby";
import styled from "styled-components";
import { use100vh } from "react-div-100vh";
import { Helmet } from "react-helmet";

// Context
import { Popup } from "../context/popup";
import { ActiveObject } from "../context/active-object";

// Components
import { Buttons } from "../product/buttons";
import { AspectRatioImageContainer } from "../containers/aspect-ratio-image-container";
import { ProductGallery } from "../images/product-gallery";

// Utils
import { FormatProductTitle } from "../utils/format-product-title";

const Page = styled.div`
  padding: 160px 0 20px 0;

  @media (max-width: 767px) {
    padding: 105px 0 20px 0;
  }
`;

const Grid = styled.div`
  width: 100%;

  & .image-and-text {
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    grid-column-gap: 20px;

    @media (max-width: 767px) {
      grid-template-columns: repeat(6, 1fr);
      grid-column-gap: 10px;
    }
  }

  & .image-container {
    grid-column: 1 / 7;

    & .inner-image-container {
      // width: min((100vh - 360px), 550px);
      // max-width: 550px;
      width: max((100vh - 490px), 550px);
      margin: 0 0 50px 0;

      @media (max-width: 1800px) {
        width: min((100vh - 360px), 550px);
        max-width: 550px;
      }

      @media (max-height: 930px) {
        width: min((100vh - 360px), 550px);
      }

      @media (max-width: 1260px) {
        width: 100%;
      }

      @media (max-width: 767px) {
        max-width: 100%;
        grid-column: span 6;

        margin: 0 0 25px 0;
      }
    }
  }

  & .text-container {
    grid-column: 7 / 13;

    @media (max-width: 767px) {
      grid-column: 1 / 7;
    }

    & .inner-text-container {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      align-content: flex-end;

      max-width: 575px;
      width: 100%;
      height: 100%;

      @media (max-width: 960px) {
        margin: 0;
      }

      @media (max-width: 767px) {
        grid-column: span 6;

        max-width: 100%;
        padding: 0;
      }

      & .product-information {
        width: 100%;
        max-width: 575px;
      }

      & .product-title {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        align-items: flex-end;

        text-transform: lowercase;

        & h1 {
          font-size: 21px;
          margin: 0;

          @media (max-width: 600px) {
            font-size: 13px;
          }
        }

        & h1,
        & p {
          line-height: 28px;
          letter-spacing: 0.03em;

          @media (max-width: 600px) {
            line-height: 18px;
          }
        }

        & p {
          font-size: 16px;
          line-height: 22px;

          margin: 0 0 0 15px;

          @media (max-width: 600px) {
            font-size: 9px;
            line-height: 15px;
          }
        }
      }
    }

    & .product-details {
      margin: 70px 0 30px 0;

      @media (max-width: 600px) {
        margin: 50px 0 20px 0;
      }

      & .line {
        display: grid;
        grid-template-columns: 100px auto;
        grid-column-gap: 20px;
      }

      & .explore-process {
        color: rgba(159, 95, 85, 1);

        & .desktop-text {
          display: block;
        }
        & .mobile-text {
          display: none;
        }

        @media (max-width: 1055px) {
          & .desktop-text {
            display: none;
          }
          & .mobile-text {
            display: block;
          }
        }
      }

      & p,
      & button {
        margin: 0;

        font-size: 14px;
        line-height: 22px;
        letter-spacing: 0.02em;
        text-align: left;

        @media (max-width: 600px) {
          font-size: 9px;
          line-height: 13px;
        }
      }
    }

    & .single-object-colour,
    & .single-object-material {
      &::after {
        content: ", ";
      }

      &:last-of-type {
        &::after {
          content: "";
        }
      }
    }

    & .product-description {
      margin: 0 0 35px 0;

      font-size: 16px;
      line-height: 22px;
      letter-spacing: 0.02em;

      & a {
        &::before {
          content: "(";
        }
        &::after {
          content: ")";
        }
      }

      @media (max-width: 960px) {
        font-size: 14px;
        line-height: 19px;
      }

      @media (max-width: 600px) {
        font-size: 11px;
        line-height: 16px;
      }
    }

    & .product-price-on-request {
      // display: grid;
      // grid-template-columns: 100px auto;

      // grid-column-gap: 20px;
      width: 100%;

      // & .button-container {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: space-between;
      // }

      & p,
      & button {
        font-size: 16px;
        line-height: 28px;
        letter-spacing: 0.03em;

        text-align: left;

        margin: 0;

        @media (max-width: 960px) {
          font-size: 14px;
          line-height: 19px;
        }

        @media (max-width: 600px) {
          font-size: 11px;
          line-height: 16px;
        }
      }
    }

    & .product-price {
      display: grid;
      grid-template-columns: 100px auto;
      grid-column-gap: 20px;

      width: 100%;

      @media (max-width: 767px) {
        grid-template-columns: 1fr 1fr;
        grid-column-gap: 10px;
      }

      & .button-container {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-between;
      }

      & p,
      & button {
        font-size: 16px;
        line-height: 28px;
        letter-spacing: 0.03em;

        text-align: left;

        margin: 0;

        @media (max-width: 960px) {
          font-size: 14px;
          line-height: 19px;
        }

        @media (max-width: 600px) {
          font-size: 11px;
          line-height: 16px;
        }
      }

      & .price-on-request {
        grid-column: 1/3;
      }

      & .add-to-cart {
        color: rgba(159, 95, 85, 1);
      }

      & .product-enquiry {
        grid-column: 4/5;

        @media (max-width: 600px) {
          margin: 0 0 0 auto;
        }
      }
    }
  }

  & .related-objects {
    width: 100%;
    grid-column: 7 / 13;
    margin: 70px 0 0 0;

    @media (max-width: 767px) {
      width: 100%;
      max-width: 100%;

      margin: 50px 0 0 0;
      padding: 0;

      grid-column: span 6;
    }

    & .section-title {
      font-size: 14px;
      line-height: 22px;
      letter-spacing: 0.02em;

      margin: 0 0 1em 0;

      @media (max-width: 600px) {
        font-size: 11px;
        line-height: 16px;
      }
    }

    & .grid {
      display: grid;
      grid-template-columns: repeat(6, 1fr);
      grid-column-gap: 20px;

      @media (max-width: 600px) {
        grid-column-gap: 10px;
      }

      & .related-object {
        grid-column: span 3;

        margin: 0 0 60px 0;

        @media (max-width: 600px) {
          margin: 0 0 40px 0;
        }

        & img {
          object-fit: cover;
          width: 100%;
          height: 100%;

          &.thumbnail {
            display: block;
          }
          &.hover-thumbnail {
            display: none;
          }
        }

        &:hover {
          & .hover-thumbnail {
            display: block;
          }
          & .thumbnail {
            display: none;
          }
        }
      }

      & .container {
        margin: 10px 0 0 0;

        & p,
        & h2 {
          font-size: 14px;
          line-height: 19px;
          letter-spacing: 0.03em;
          text-transform: lowercase;

          margin: 0;

          @media (max-width: 600px) {
            font-size: 9px;
            line-height: 13px;
          }
        }
      }
    }
  }
`;

export const ProductContent = ({ product, context, data }) => {
  // Height
  const height = use100vh();

  // Popups
  const [activePopup, setActivePopup] = useContext(Popup);
  const [activeObject, setActiveObject] = useContext(ActiveObject);

  // Lightbox
  const [isLightboxOpen, setLightBoxOpen] = useState(false);

  const [quantity, setQuantity] = useState(1);
  const [variant, setVariant] = useState(product.variants[0]);

  const productVariant =
    context.store.client.product.helpers.variantForOptions(product, variant) ||
    variant;

  const [available, setAvailable] = useState(productVariant.availableForSale);

  useEffect(() => {
    let defaultOptionValues = {};
    product.options.forEach((selector) => {
      defaultOptionValues[selector.name] = selector.values[0];
    });
    setVariant(defaultOptionValues);
  }, []);

  useEffect(() => {
    checkAvailability(product.shopifyId);
  }, [productVariant]);

  useEffect(() => {
    setActiveObject({
      object: {
        product: data.shopifyProduct,
        data: data.prismicObject.data,
      },
    });
  }, []);

  const checkAvailability = (productId) => {
    context.store.client.product.fetch(productId).then((product) => {
      // this checks the currently selected variant for availability
      const result = product.variants.filter(
        (variant) => variant.id === productVariant.shopifyId
      );
      setAvailable(result[0].available);
    });
  };

  const processImages = data.prismicObject.data.images.filter(
    (image) => image.image.fluid !== null
  );
  const processText = data.prismicObject.data.text.text;
  const isProcessVisible = processImages.length >= 1 || processText.length >= 1;

  // const handleOptionChange = event => {
  //   const { target } = event;
  //   setVariant(prevState => ({
  //     ...prevState,
  //     [target.name]: target.value,
  //   }));
  // };

  const relatedObjects = data.prismicObject.data.related_objects
    .filter((related) => related.related_object.document !== null)
    .filter((product, index) => index <= 1)
    .map((object, index) => (
      <div
        key={`single_product_${index}_${object.related_object.id}`}
        className="related-object"
      >
        <Link to={`${object.related_object.document.url}`}>
          <AspectRatioImageContainer image={null} padding={116}>
            {object.related_object.document.data.grid_thumbnail.fluid !==
              null && (
              <img
                className="thumbnail"
                srcSet={
                  object.related_object.document.data.grid_thumbnail.fluid
                    .srcSet
                }
                src={
                  object.related_object.document.data.grid_thumbnail.fluid.src
                }
                alt={object.related_object.document.data.grid_thumbnail.alt}
                loading="lazy"
              />
            )}

            {object.related_object.document.data.grid_hover_thumbnail.fluid !==
              null && (
              <img
                className="hover-thumbnail"
                srcSet={
                  object.related_object.document.data.grid_hover_thumbnail.fluid
                    .srcSet
                }
                src={
                  object.related_object.document.data.grid_hover_thumbnail.fluid
                    .src
                }
                alt={
                  object.related_object.document.data.grid_hover_thumbnail.alt
                }
                loading="lazy"
              />
            )}
          </AspectRatioImageContainer>

          <div className="container">
            <div className="left">
              {object.related_object.document.data.shopify_product !== null && (
                <p className="maker">
                  {
                    FormatProductTitle(
                      object.related_object.document.data.shopify_product.title
                    )[0]
                  }
                </p>
              )}
              {object.related_object.document.data.shopify_product !== null && (
                <h2 className="title">
                  {
                    FormatProductTitle(
                      object.related_object.document.data.shopify_product.title
                    )[1]
                  }
                </h2>
              )}
            </div>
          </div>
        </Link>
      </div>
    ));

  const objectMaterials = data.prismicObject.data.materials
    .filter((material) => material.single_material !== null)
    .map((material, index) => (
      <span
        key={`single_object_material_${index}`}
        className="single-object-material"
      >
        {material.single_material.toLowerCase()}
      </span>
    ));

  const objectColours = data.prismicObject.data.colours
    .filter((material) => material.single_colour !== null)
    .map((colour, index) => (
      <span
        key={`single_object_colour_${index}`}
        className="single-object-colour"
      >
        {colour.single_colour.toLowerCase()}
      </span>
    ));

  return (
    <>
      <Helmet
        title={`${data.shopifyProduct.title} | Metamorphoses`}
        meta={[
          {
            name: "title",
            content: `${data.shopifyProduct.title} | Metamorphoses`,
          },
          {
            property: "og:title",
            content: `${data.shopifyProduct.title} | Metamorphoses`,
          },
          {
            property: "og:image",
            content: `${data.shopifyProduct.images[0].originalSrc}`,
          },
          {
            property: "og:image:secure_url",
            content: `${data.shopifyProduct.images[0].originalSrc}`,
          },
          {
            property: "og:image:width",
            content: `1200`,
          },
          {
            property: "og:image:height",
            content: `630`,
          },
          {
            property: "og:locale",
            content: `en`,
          },
          {
            name: "description",
            content: `${data.shopifyProduct.description} | Metamorphoses
						`,
          },
          {
            property: "og:description",
            content: `${data.shopifyProduct.description} | Metamorphoses
						`,
          },
          {
            property: "twitter:title",
            content: `${data.shopifyProduct.title} | Metamorphoses`,
          },
          {
            property: "twitter:description",
            content: `${data.shopifyProduct.description} | Metamorphoses
						`,
          },
          {
            property: "twitter:card",
            content: `summary_large_image`,
          },
          {
            property: "twitter:image",
            content: `${data.shopifyProduct.images[0].originalSrc}`,
          },
          {
            property: "og:url",
            content: `https://metamorphosesobjects.com${data.prismicObject.url}`,
          },
          {
            property: "twitter:url",
            content: `https://metamorphosesobjects.com${data.prismicObject.url}`,
          },
        ]}
      >
        {/* <link
          rel="canonical"
          href={`https://metamorphosesobjects.com${data.prismicObject.url}`}
        /> */}
      </Helmet>

      <Page>
        <Grid>
          <div className="image-and-text">
            <div className="image-container">
              <div className="inner-image-container">
                <ProductGallery
                  images={data.shopifyProduct.images}
                  isLightboxOpen={isLightboxOpen}
                  setLightBoxOpen={setLightBoxOpen}
                  title={data.shopifyProduct.title}
                  maxHeight={`calc(${height}px - 260px)`}
                />
              </div>
            </div>

            <div className="text-container">
              <div className="inner-text-container">
                <div className="product-information">
                  <div className="product-title">
                    <h1>
                      {FormatProductTitle(data.shopifyProduct.title)[0]} <br />
                      {FormatProductTitle(data.shopifyProduct.title)[1]}
                    </h1>
                    <p className="year">{data.prismicObject.data.year}</p>
                  </div>

                  <div className="product-details">
                    {data.prismicObject.data.edition !== null && (
                      <div className="line">
                        <p>edition</p>
                        <p className="text">
                          {data.prismicObject.data.edition.toLowerCase()}
                        </p>
                      </div>
                    )}
                    {objectMaterials.length >= 1 && (
                      <div className="line">
                        <p>material</p>
                        <p className="text">{objectMaterials}</p>
                      </div>
                    )}

                    {objectColours.length >= 1 && (
                      <div className="line">
                        <p>colour</p>
                        <p className="text">{objectColours}</p>
                      </div>
                    )}

                    {data.prismicObject.data.dimensions !== null && (
                      <div className="line">
                        <p>dimensions</p>
                        <p className="text">
                          {data.prismicObject.data.dimensions}
                        </p>
                      </div>
                    )}

                    {isProcessVisible && (
                      <div className="line">
                        <p>process</p>
                        <button
                          onClick={() => setActivePopup("product-process")}
                          className="explore-process"
                        >
                          <span className="desktop-text">
                            (click here to explore the process of metamorphoses)
                          </span>

                          <span className="mobile-text">
                            (click here to explore)
                          </span>
                        </button>
                      </div>
                    )}
                  </div>

                  <div
                    className="product-description"
                    dangerouslySetInnerHTML={{
                      __html: data.shopifyProduct.descriptionHtml,
                    }}
                  />

                  {data.prismicObject.data.price_on_request === true ||
                  productVariant.priceV2.amount === "0.0" ? (
                    <div className="product-price-on-request">
                      <p className="price-on-request">Price on request</p>

                      <button
                        onClick={() => setActivePopup("product-enquiry")}
                        className="product-enquiry"
                      >
                        (enquire)
                      </button>
                    </div>
                  ) : (
                    <div className="product-price">
                      <p>
                        {new Intl.NumberFormat("en-GB", {
                          style: "currency",
                          currency: productVariant.priceV2.currencyCode,
                        }).format(productVariant.priceV2.amount)}
                      </p>

                      <div className="button-container">
                        <Buttons
                          context={context}
                          available={available}
                          quantity={quantity}
                          productVariant={productVariant}
                          product={data.shopifyProduct}
                        />

                        {data.prismicObject.data.price_on_request === true && (
                          <p className="price-on-request">Price on request</p>
                        )}

                        <button
                          onClick={() => setActivePopup("product-enquiry")}
                          className="product-enquiry"
                        >
                          (enquire)
                        </button>
                      </div>
                    </div>
                  )}

                  {/* {data.prismicObject.data.price_on_request === false ||
                  productVariant.priceV2.amount !== "0.0" ? (
                    <div className="product-price">
                      <p>
                        {new Intl.NumberFormat("en-GB", {
                          style: "currency",
                          currency: productVariant.priceV2.currencyCode,
                        }).format(productVariant.priceV2.amount)}
                      </p>

                      <div className="button-container">
                        <Buttons
                          context={context}
                          available={available}
                          quantity={quantity}
                          productVariant={productVariant}
                          product={data.shopifyProduct}
                        />

                        {data.prismicObject.data.price_on_request === true && (
                          <p className="price-on-request">Price on request</p>
                        )}

                        <button
                          onClick={() => setActivePopup("product-enquiry")}
                          className="product-enquiry"
                        >
                          (enquire)
                        </button>
                      </div>
                    </div>
                  ) : (
                    <div className="product-price-on-request">
                      <p className="price-on-request">Price on request</p>

                      <button
                        onClick={() => setActivePopup("product-enquiry")}
                        className="product-enquiry"
                      >
                        (enquire)
                      </button>
                    </div>
                  )} */}
                </div>
              </div>
            </div>
          </div>

          {relatedObjects.length >= 1 && (
            <div className="image-and-text">
              <div className="spacer" />
              <div className="related-objects">
                <p className="section-title">related objects</p>
                <div className="grid">{relatedObjects}</div>
              </div>
            </div>
          )}
        </Grid>

        <>
          <form
            name="object-enquiry"
            data-netlify="true"
            netlify-honeypot="bot-field"
            hidden
          >
            <input type="hidden" name="firstName" />
            <input type="hidden" name="lastName" />
            <input type="hidden" name="email" />
            <input type="hidden" name="object" />
            <input type="hidden" name="country" />
            <textarea type="hidden" name="message" />
          </form>
        </>
      </Page>
    </>
  );
};
