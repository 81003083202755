import React, { useContext } from "react";
import { graphql } from "gatsby";
import styled from "styled-components";

// Previews
import { withPreview } from "gatsby-source-prismic";

// Context
import StoreContext from "../components/context/store";

// Utils

import { ProductContent } from "../components/object/productContent";
import { ProductUndefinedContent } from "../components/object/productUndefinedContent";

const Page = styled.div`
  padding: 160px 0 20px 0;

  @media (max-width: 767px) {
    padding: 105px 0 20px 0;
  }
`;

const Grid = styled.div`
  width: 100%;

  & .image-and-text {
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    grid-column-gap: 20px;

    @media (max-width: 767px) {
      grid-template-columns: repeat(6, 1fr);
      grid-column-gap: 10px;
    }
  }

  & .image-container {
    grid-column: 1 / 7;

    & .inner-image-container {
      // width: min((100vh - 360px), 550px);
      // max-width: 550px;
      width: max((100vh - 490px), 550px);
      margin: 0 0 50px 0;

      @media (max-width: 1800px) {
        width: min((100vh - 360px), 550px);
        max-width: 550px;
      }

      @media (max-height: 930px) {
        width: min((100vh - 360px), 550px);
      }

      @media (max-width: 1260px) {
        width: 100%;
      }

      @media (max-width: 767px) {
        max-width: 100%;
        grid-column: span 6;

        margin: 0 0 25px 0;
      }
    }
  }

  & .text-container {
    grid-column: 7 / 13;

    @media (max-width: 767px) {
      grid-column: 1 / 7;
    }

    & .inner-text-container {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      align-content: flex-end;

      max-width: 575px;
      width: 100%;
      height: 100%;

      @media (max-width: 960px) {
        margin: 0;
      }

      @media (max-width: 767px) {
        grid-column: span 6;

        max-width: 100%;
        padding: 0;
      }

      & .product-information {
        width: 100%;
        max-width: 575px;
      }

      & .product-title {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        align-items: flex-end;

        text-transform: lowercase;

        & h1 {
          font-size: 21px;
          margin: 0;

          @media (max-width: 600px) {
            font-size: 13px;
          }
        }

        & h1,
        & p {
          line-height: 28px;
          letter-spacing: 0.03em;

          @media (max-width: 600px) {
            line-height: 18px;
          }
        }

        & p {
          font-size: 16px;
          line-height: 22px;

          margin: 0 0 0 15px;

          @media (max-width: 600px) {
            font-size: 9px;
            line-height: 15px;
          }
        }
      }
    }

    & .product-details {
      margin: 70px 0 30px 0;

      @media (max-width: 600px) {
        margin: 50px 0 20px 0;
      }

      & .line {
        display: grid;
        grid-template-columns: 100px auto;
        grid-column-gap: 20px;
      }

      & .explore-process {
        color: rgba(159, 95, 85, 1);
      }

      & p,
      & button {
        margin: 0;

        font-size: 14px;
        line-height: 22px;
        letter-spacing: 0.02em;
        text-align: left;

        @media (max-width: 600px) {
          font-size: 9px;
          line-height: 13px;
        }
      }
    }

    & .single-object-colour,
    & .single-object-material {
      &::after {
        content: ", ";
      }

      &:last-of-type {
        &::after {
          content: "";
        }
      }
    }

    & .product-description {
      margin: 0 0 35px 0;

      font-size: 16px;
      line-height: 22px;
      letter-spacing: 0.02em;

      & a {
        &::before {
          content: "(";
        }
        &::after {
          content: ")";
        }
      }

      @media (max-width: 960px) {
        font-size: 14px;
        line-height: 19px;
      }

      @media (max-width: 600px) {
        font-size: 11px;
        line-height: 16px;
      }
    }

    & .product-price-on-request {
      // display: grid;
      // grid-template-columns: 100px auto;

      // grid-column-gap: 20px;
      width: 100%;

      // & .button-container {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: space-between;
      // }

      & p,
      & button {
        font-size: 16px;
        line-height: 28px;
        letter-spacing: 0.03em;

        text-align: left;

        margin: 0;

        @media (max-width: 960px) {
          font-size: 14px;
          line-height: 19px;
        }

        @media (max-width: 600px) {
          font-size: 11px;
          line-height: 16px;
        }
      }
    }

    & .product-price {
      display: grid;
      grid-template-columns: 100px auto;
      grid-column-gap: 20px;

      width: 100%;

      @media (max-width: 767px) {
        grid-template-columns: 1fr 1fr;
        grid-column-gap: 10px;
      }

      & .button-container {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-between;
      }

      & p,
      & button {
        font-size: 16px;
        line-height: 28px;
        letter-spacing: 0.03em;

        text-align: left;

        margin: 0;

        @media (max-width: 960px) {
          font-size: 14px;
          line-height: 19px;
        }

        @media (max-width: 600px) {
          font-size: 11px;
          line-height: 16px;
        }
      }

      & .price-on-request {
        grid-column: 1/3;
      }

      & .add-to-cart {
        color: rgba(159, 95, 85, 1);
      }

      & .product-enquiry {
        grid-column: 4/5;

        @media (max-width: 600px) {
          margin: 0 0 0 auto;
        }
      }
    }
  }

  & .related-objects {
    width: 100%;
    grid-column: 7 / 13;
    margin: 70px 0 0 0;

    @media (max-width: 767px) {
      width: 100%;
      max-width: 100%;

      margin: 50px 0 0 0;
      padding: 0;

      grid-column: span 6;
    }

    & .section-title {
      font-size: 14px;
      line-height: 22px;
      letter-spacing: 0.02em;

      margin: 0 0 1em 0;

      @media (max-width: 600px) {
        font-size: 11px;
        line-height: 16px;
      }
    }

    & .grid {
      display: grid;
      grid-template-columns: repeat(6, 1fr);
      grid-column-gap: 20px;

      @media (max-width: 600px) {
        grid-column-gap: 10px;
      }

      & .related-object {
        grid-column: span 3;

        margin: 0 0 60px 0;

        @media (max-width: 600px) {
          margin: 0 0 40px 0;
        }

        & img {
          object-fit: cover;
          width: 100%;
          height: 100%;

          &.thumbnail {
            display: block;
          }
          &.hover-thumbnail {
            display: none;
          }
        }

        &:hover {
          & .hover-thumbnail {
            display: block;
          }
          & .thumbnail {
            display: none;
          }
        }
      }

      & .container {
        margin: 10px 0 0 0;

        & p,
        & h2 {
          font-size: 14px;
          line-height: 19px;
          letter-spacing: 0.03em;
          text-transform: lowercase;

          margin: 0;

          @media (max-width: 600px) {
            font-size: 9px;
            line-height: 13px;
          }
        }
      }
    }
  }
`;

const Product = ({ data }) => {
  const context = useContext(StoreContext);

  // Product
  const product = data.shopifyProduct;

  if (product !== undefined && product !== null) {
    return <ProductContent data={data} product={product} context={context} />;
  } else {
    return (
      <ProductUndefinedContent
        data={data}
        product={data.prismicObject.data.shopify_product}
        context={context}
      />
    );
  }
};

export default withPreview(Product);

export const query = graphql`
  query ($uid: String!, $shopifyProduct: String!) {
    prismicObject(uid: { eq: $uid }) {
      _previewable
      url
      data {
        shopify_product
        maker {
          document {
            ... on PrismicMaker {
              id
              data {
                name {
                  text
                }
              }
            }
          }
        }
        edition
        materials {
          single_material
        }
        colours {
          single_colour
        }
        dimensions
        year
        price_on_request
        section_title {
          html
        }
        text {
          text
          html
        }
        credits {
          html
        }
        images {
          image {
            alt
            fluid {
              src
              srcSet
            }
            dimensions {
              width
              height
            }
          }
          caption {
            html
          }
        }
        related_objects {
          related_object {
            document {
              ... on PrismicObject {
                id
                url
                data {
                  grid_thumbnail {
                    alt
                    fluid {
                      src
                      srcSet
                    }
                  }
                  grid_hover_thumbnail {
                    alt
                    fluid {
                      src
                      srcSet
                    }
                  }
                  shopify_product
                  maker {
                    document {
                      ... on PrismicMaker {
                        id
                        data {
                          name {
                            text
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    shopifyProduct(handle: { eq: $shopifyProduct }) {
      handle
      id
      title
      productType
      descriptionHtml
      description
      shopifyId
      availableForSale
      priceRange {
        minVariantPrice {
          amount
        }
        maxVariantPrice {
          amount
        }
      }
      options {
        id
        name
        values
      }
      variants {
        id
        title
        priceV2 {
          amount
          currencyCode
        }
        availableForSale
        shopifyId
        selectedOptions {
          name
          value
        }
      }
      images {
        id
        originalSrc
        width
        height
      }
    }
  }
`;
